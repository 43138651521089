import { Box } from "@mui/material";

const EmotionStatus = ({name, amount, color}) => {
  return (
    <Box display="flex" flexDirection="row" marginTop="5px" marginBottom="3px">
      <Box marginRight="5px">
        {name}
      </Box>
      <Box width="200px" border="1px solid #AAAAAA" borderRadius="20px">
        <Box width={amount + "%"} height="20px" borderRadius="20px" marginLeft="0" sx={{backgroundColor: color}}/>
      </Box>
    </Box>
  )
}
export default EmotionStatus;