import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import '../style/css/register.css'
import {
  Box, Button, Card, CardActions,
  CardContent,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel, OutlinedInput,
  Radio,
  RadioGroup, Stack, TextField
} from "@mui/material";
import { buildTimeValue } from "@testing-library/user-event/dist/utils";

const RegisterPage = (props) => {

  const [id, setId] = useState('');
  const [pw, setPw] = useState('');

  const [isAgree, setAgree] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    console.log(id, pw);
  }, []);

  const clickRegister = async () => {
    if(!id || !pw) {
      alert('아이디와 비밀번호 모두 입력해주세요');
      return;
    }

    if(!isAgree) {
      alert("개인정보 이용동의를 해주세요");
      return;
    }

    const regExp = /^[A-Za-z0-9]{4,20}$/

    if (!regExp.test(id)) {
      alert('아이디는 영문 4자 이상, 20자 이하 입니다.');
      setId('');
      return;
    }

    if (!regExp.test(pw)) {
      alert('비밀번호는 영문 4자 이상, 20자 이하입니다.');
      return;
    }

    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER_API}/api/v1/auth/register`, {id: id, pw: pw}, {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      alert('회원가입을 성공하였습니다.');

      navigate('/login');
    } catch (e) {
      console.log('id check error', e);
      alert('중복되는 아이디 입니다. 다시 입력해주세요');
      setId('');
    }
  }

  const handleIdChange = (e) => {
    setId(e.target.value);
  }

  const handlePasswordChange = (e) => {
    setPw(e.target.value);
  }

  const handleAgreeChange = (evt, value) => {
    setAgree(value === "agree");
  }

  const isMobile = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /android|iPhone|iPad|iPod/i.test(userAgent);
  };

  return (
    <>
      <Container>
        <Box display="flex" flexDirection="column" alignItems="center" my={4} gap={4} p={7}>
          <Box sx={{fontSize: '25px', fontWeight: 'bold', color: 'rgb(25, 118, 210)'}}>
            NetPersona
          </Box>
          <Card sx={{width: isMobile() ? null : '455px'}}>
            <CardContent>
              <Stack
                component="form"
                noValidate
                autoComplete="off"
              >
                <OutlinedInput placeholder="아이디" fullWidth onChange={handleIdChange}/>

                <OutlinedInput type="password" placeholder="비밀번호" fullWidth onChange={handlePasswordChange}/>

                <FormControl sx={{flexDirection: "row"}}>
                  <FormLabel sx={{marginRight: "15px"}}>개인정보 이용동의</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="agree"
                    name="radio-buttons-group"
                    sx={{marginTop: "10px"}}
                    onChange={handleAgreeChange}
                  >
                    <FormControlLabel value="agree" control={<Radio/>} label="동의" size="small"/>
                    <FormControlLabel value="disagree" control={<Radio/>} label="비동의" size="small"/>
                  </RadioGroup>
                </FormControl>

                <Button variant="contained" onClick={clickRegister}>회원가입</Button>
              </Stack>
            </CardContent>
            <CardActions>
              계정이 이미 있으신가요? <Button size="small" onClick={() => navigate('/login')}>로그인 →</Button>
            </CardActions>
          </Card>
        </Box>
      </Container>
    </>
  )
}
export default RegisterPage;