import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import DataManager from "../data/DataManager";
import {
  Box,
  Button, Card, CardActions, CardContent, Container,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from "@mui/material";

const LoginPage = () => {

  const [id, setId] = useState('');
  const [pw, setPw] = useState('');

  const navigate = useNavigate();

  const clickLogin = async () => {
    console.log(id, ' ', pw);
    if(!id || !pw) {
      alert('아이디 또는 비밀번호를 입력해주세요');
      return;
    }

    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER_API}/api/v1/auth/login`, {id: id, pw: pw}, {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      const { memId, userId, chatId, sessionId } = res.data.result;

      DataManager.memId = memId;
      DataManager.userId = userId;
      DataManager.chatId = chatId;
      DataManager.sessionId = sessionId;

      navigate('/');
    } catch (e) {
      alert('아이디 또는 비밀번호를 확인해주세요');
      setId('');
      setPw('');
    }
  }

  const handleIdChange = (e) => {
    setId(e.target.value);
  }

  const handlePasswordChange = (e) => {
    setPw(e.target.value);
  }

  const isMobile = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /android|iPhone|iPad|iPod/i.test(userAgent);
  };

  return (
    <>
      <Container>
        <Box display="flex" flexDirection="column" alignItems="center" my={4} gap={4}>
          <Box sx={{width: isMobile() ? null : "455px"}} display="flex" justifyContent="space-between" flexDirection={isMobile() ? "column" : "row"}>
            <Box>
              <img width={isMobile() ? "100%" : "190px"} src="./icon_ci.png" loading="lazy" alt="icon"/>
            </Box>
            <Box sx={{fontSize: '27px', fontWeight: 'bold', color: 'rgb(25, 118, 210)', textAlign: 'center'}}>
              NetPersona
            </Box>
          </Box>
          <Card sx={{width: isMobile() ? null : "455px"}}>
            <CardContent>
              <Stack
                component="form"
                noValidate
                autoComplete="off"
              >
                <TextField label="아이디" fullWidth variant="standard" onChange={handleIdChange}/>
                <TextField type="password" label="비밀번호" fullWidth variant="standard" margin="normal" onChange={handlePasswordChange}/>
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="gpt"
                  name="radio-buttons-group"
                  sx={{marginTop: "10px"}}
                >
                  <FormControlLabel value="gpt" control={<Radio/>} label="Chat-GPT" size="small"/>
                  <FormControlLabel value="gemini" control={<Radio/>} label="Gemini" size="small" disabled/>
                  <FormControlLabel value="claude" control={<Radio/>} label="Claude" size="small" disabled/>
                </RadioGroup>

                <Button variant="contained" onClick={clickLogin}>로그인</Button>
              </Stack>
            </CardContent>

            <CardActions>
              계정이 없으신가요? <Button size="small" onClick={() => navigate('/register')}>회원가입 →</Button>
            </CardActions>
          </Card>

          <Box style={{marginTop:"20px"}}>
            넷스트림은 유저와 AI간 깊이 있는 대화를 가능하게 하기 위해 ‘기억’을 만들고 있습니다. <br/>
            AI 기억 프로젝트에 동참해 주시겠습니까? <br/>
            테스트는 7월 30일까지 진행이 될 예정입니다. <br/>
          </Box>

        </Box>
      </Container>

    </>
  )
}
export default LoginPage;