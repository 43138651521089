import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import ChatPage from "./page/ChatPage";
import LoginPage from "./page/LoginPage";
import { useEffect } from "react";
import RegisterPage from "./page/RegisterPage";
import axios from "axios";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="register" element={<RegisterPage/>}/>
        <Route path="/login" element={<LoginPage/>}/>
        <Route path="/" element={<ChatPage/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
