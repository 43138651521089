import { useEffect, useRef, useState } from 'react';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DataManager from "../data/DataManager";
import ReactMarkdown from 'react-markdown'
import { Box, Card, Container, OutlinedInput, Stack } from "@mui/material";
import '../style/css/chat.css';
import EmotionStatus from "../components/EmotionStatus";

const ChatPage = () => {
  const [history, setHistory] = useState([]);
  const [message, setMessage] = useState('');

  const sessionId = DataManager.sessionId;

  const scrollRef = useRef();
  const navigate = useNavigate();

  // 감정
  const [happy, setHappy] = useState(0);
  const [sad, setSad] = useState(0);
  const [angry, setAngry] = useState(0);
  const [timid, setTimid] = useState(0);
  const [grumpy, setGrumpy]= useState(0);

  useEffect(() => {
    if(!sessionId) {
      navigate('/login');
    }
  }, [sessionId, navigate]);

  useEffect(() => {
    fetchHistory();
  }, []);

  useEffect(() => {
    scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
  }, [history]);

  const fetchHistory = async () => {
    const config = {params: {count: 10}};
    const response = await axios.get(`${process.env.REACT_APP_SERVER_API}/api/v1/chat/find/${sessionId}`, config);
    const { chats } = response.data.result;

    setHistory(chats.reverse());
  }

  const sendMessage = async () => {
    const content = message;

    setMessage('');
    setHistory(prevChat => [...prevChat, { speakerRole: 'user', content: content }]);

    const res = await axios.post(`${process.env.REACT_APP_SERVER_API}/api/v1/chat`, {sessionId: sessionId, message: content}, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
    const { role, answer } = res.data.result;

    setHistory(prevChat => [...prevChat, { speakerRole: 'assistant', content: answer }]);
  }

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      sendMessage();
    }
  }

  const isMobile = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /android|iPhone|iPad|iPod/i.test(userAgent);
  };

  const parseJsonText = (text) => {
    try {
      return JSON.parse(text);
    } catch (e) {
      return null;
    }
  }

  useEffect(() => {
    for(let i = history.length - 1; i >= 0; i--) {
      const item = history[i];

      if (item.speakerRole === "assistant") {
        const split = item.content.split("\n");

        const emotion = parseJsonText(split[split.length - 1]);
        if(emotion === null) {
          continue;
        }

        const {happy, sad, angry, timid, grumpy} = emotion;

        console.log('emotion : ', emotion);

        setHappy(happy);
        setSad(sad);
        setAngry(angry);
        setTimid(timid);
        setGrumpy(grumpy);

        return;
      }
    }
  }, [history]);

  return (
    <Container>
      <Box position="absolute" right="10px" top="10px" display={isMobile() ? "none" : null} >
        <Card sx={{padding: "30px 10px 30px 10px", width:"300px", height:"auto"}}>
          <Stack>
            <EmotionStatus name="기쁨" amount={happy} color="#FFFF00"/>
            <EmotionStatus name="슬픔" amount={sad} color="#0B3176"/>
            <EmotionStatus name="버럭" amount={angry} color="#FF0000"/>
            <EmotionStatus name="소심" amount={timid} color="#663782"/>
            <EmotionStatus name="까칠" amount={grumpy} color="#40812D"/>
          </Stack>
        </Card>
      </Box>
      <Box className="scrollable" width="90%" height="85vh" flexDirection="column" ref={scrollRef}>
        {
          history.map((item, key) => {
            const role = item.speakerRole;

            let content = item.content;
            const split = content.split("\n");
            if(parseJsonText(split[split.length - 1])) {
              split.pop();
              content = split.join('\n').trim();
            }

            return (
              <Box key={key} display="flex" padding="10px" flexDirection="column">
                <Box display="inline-block"
                     marginLeft={role === "assistant" ? '5px' : null}
                     marginRight={role === "user" ? '5px' : null}
                     width="auto"
                     sx={{
                       fontSize: "13px",
                       borderRadius: "20px",
                       borderTopLeftRadius: role === "assistant" ? "5px" : null,
                       borderTopRightRadius: role === "user" ? "5px" : null,
                       backgroundColor: role === "user" ? 'rgb(25, 118, 210)' : "#F6F7FB",
                       color: role === "user" ? "#FFFFFF" : "#000000"
                     }}>

                  <Stack width="auto" maxWidth={isMobile() ? "300px" : "550px"} padding="10px">
                    <Box marginLeft="0px" marginBottom="5px">
                      {role + " " + (role === "user" ? DataManager.userId : DataManager.chatId)}
                    </Box>
                    <ReactMarkdown children={content} />
                  </Stack>
                </Box>
              </Box>
            )
          })
        }
        <Box height="20px"/>
      </Box>

      <Box height="12vh" width="80%" marginTop="20px">
        <OutlinedInput value={message} placeholder="Please enter text" fullWidth onChange={handleMessageChange} onKeyPress={handleKeyPress}/>
      </Box>
    </Container>
  );
};

export default ChatPage;